import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { BenIcon, Button, Card, Flex, Input,Skeleton,Spinner,Text } from '@pancakeswap-libs/uikit'
import styled, { ThemeContext } from 'styled-components'
import { useParams } from 'react-router-dom'
import { useActiveWeb3React } from 'hooks'
import {  useGriLiquidityActionHandlers, useGriLiquidityState, useTokenEchangeInfo } from 'state/griDexLiquidity/hooks'
import { useTokenInfo } from 'hooks/Tokens'
import BigNumber from 'bignumber.js'
import toPrecision from 'utils/getPrecision'
import useI18n from 'hooks/useI18n';
import Loading from 'components/Loading';
import PageHeader from './PageHeader'
import SelectToken from './SelectToken'
import RechargeAmount from './RechargeAmount'
import ExchangeRate from './ExchangeRate'
import EfficiencyWaring from './EfficiencyWaring'
import LiquidityChartRangeInput from '../../components/LiquidityChartRangeInput'
import useMatchBreakpoints from '../../hooks/useMatchBreakpoints'
import ButtonTips from './ButtonTips'
import getRate from './utils/getRate'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import GridexChart from './GridexChart'
import { useDefaultsFromURLSearch } from '../../state/swap/hooks'


const LoadingContainer = styled.div`
  max-width: 250px;
  width: 100%;
`

const BodyWrapper = styled(Card)`
  position: relative;
  min-height: 300px;
  max-width:880px;
  width: 100%;
  z-index: 5;
  padding:30px;
  ${({ theme }) => theme.mediaQueries.sm} {
    max-width:480px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    max-width:880px;
  }
`
 const Wrapper = styled.div`
  position: relative;
`
const StyleButton= styled(Button)`
  border:1px solid ${({ theme })=>theme.colors.borderColor};
  color: ${({ theme })=>theme.colors.text};
  :focus{
    box-shadow: none !important;
  }
  :hover{
    box-shadow: none !important;
  }
`
const ExchangeRatePanel = styled(Flex)<{state:boolean}>`
  position: relative;
  opacity: ${(props) => (props.state?1:0.2)};
  pointer-events: ${(props) => (props.state ? "initial" : "none")};
`
const EfficiencyWaringPanel = styled(Flex)`
  position: absolute;
  top:0px;
  left:0px;
  height:100% ;
  width:100% ;
`
const  KeepOutPanel= styled(Flex)<{state:boolean}>`
    opacity: ${(props) => (props.state?1:0.2)};
    pointer-events: ${(props) => (props.state ? "initial" : "none")};
    height: ${(props) => (props.state?'auto':"250px")};
    flex:${(props) => (props.state?'1':"none")};
`
const  UlockPanel= styled(Flex)`
       width: 130px;
       height: 48px;
       align-items: center;
`


interface AddContractModalProp {
  onDismiss?: () => void
  onOk?: (string) => void
}
interface RouteParams {
  currencyIdA: string;
  currencyIdB: string;
}

const GriDexAddLiquidity: React.FC<AddContractModalProp> = () => {
    // const { currencyIdA, currencyIdB } = useParams<RouteParams>();
    const loadedUrlParams = useDefaultsFromURLSearch();
    const TranslateString = useI18n();
    const { account } = useActiveWeb3React();
    const [status,setStatus]=useState(false); 
    const { isMobile,isTablet } = useMatchBreakpoints();
    const isResponse =isMobile || isTablet;
    // redex 变量缓存
    const {feeTier,clearState,priceInfo,selectToken,leftRangeTypedValue,rightRangeTypedValue}=useGriLiquidityState();
    // 获取汇率价格 pool chart 数据
    const {chartData,state,isValidPairState}=useTokenEchangeInfo(selectToken.tokenA,selectToken.tokenB);
    const tokenA=useTokenInfo(selectToken.tokenA);
    const tokenB=useTokenInfo(selectToken.tokenB);
    // 最低最高汇率初始值
    const {onSelectToken,onSetAllrange,onSetClearAll,onLeftRangeInput,onRightRangeInput}=useGriLiquidityActionHandlers();
    const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()

    // url Params
    useEffect(()=>{
      if(loadedUrlParams?.inputCurrencyId && loadedUrlParams?.outputCurrencyId){
        onSelectToken({tokenA:loadedUrlParams?.inputCurrencyId,tokenB:loadedUrlParams?.outputCurrencyId});
      }
    },[loadedUrlParams?.inputCurrencyId,loadedUrlParams?.outputCurrencyId,onSelectToken])

    // switch token
    const prevSelectTokenRef = useRef(selectToken);
    useEffect(()=>{
       if(!isValidPairState && selectToken.tokenB!=="" && selectToken.tokenA!==""){

        if(prevSelectTokenRef.current.tokenA !== selectToken.tokenA ||
           prevSelectTokenRef.current.tokenB !== selectToken.tokenB)
        {
          const switchToekn={tokenA:selectToken.tokenB,tokenB:selectToken.tokenA}
          onSelectToken(switchToekn);
          prevSelectTokenRef.current=switchToekn;
        }
         
       }
    },
    [isValidPairState,onSelectToken,selectToken])

    // 图表拖动计算最近的值兑换率
    const leftEnd=useCallback((val:string)=>{
       // const res=getRate(false,chartData,val);
       onLeftRangeInput(val)
       onSetAllrange([val,rightRangeTypedValue]);
    },[onLeftRangeInput,onSetAllrange,rightRangeTypedValue])

    const rightEnd=useCallback((val:string)=>{
       // const res=getRate(true,chartData,val);
        onRightRangeInput(val)
        onSetAllrange([leftRangeTypedValue,val]);
    },[onRightRangeInput,onSetAllrange,leftRangeTypedValue])

    const setRangValue=useCallback(()=>{
      if(chartData.length>0){
        onLeftRangeInput(chartData[0].price0.toString());
        onRightRangeInput(chartData[chartData.length-1].price0.toString())
      }
      setStatus(!status)
    },[chartData,status,onLeftRangeInput,onRightRangeInput])
     
    
    useEffect(()=>{
      return ()=>{
        onSetClearAll(true);
        onSetClearAll(false);
      }
    },[onSetClearAll])

    const chartTips=()=>{
      if(priceInfo?.price==="0"){
        return <>{`${TranslateString(11151,"This trading pair is not supported. Please add liquidity in our classic exchange first for ")}${tokenA?.symbol}/${tokenB?.symbol}${TranslateString(11152,'.')}`}</>;
      }
      if(priceInfo?.price==="-1"){
        return <>{TranslateString(11161,"This trading pair is not supported because the price ratio is too low")}</>
      }
      if (!selectToken?.tokenA || !selectToken?.tokenB) {
        return <>{TranslateString(11162,"Please select a token pair")}</>
      }
      return <LoadingContainer><Loading/></LoadingContainer>
    }
    // 汇率范围组件
    const conversionRate=()=>(
        <>
            
           <KeepOutPanel state={chartData.length>0 && priceInfo?.price!=="" && priceInfo?.price!=="0"} style={{position:"relative"}}  mb={3} flexDirection="column" >
                    <Text fontSize='16px' bold>{TranslateString(11106,"Set Price Range")}</Text>
                    {chartData.length>0 &&
                     priceInfo?.price!==""&& 
                     leftRangeTypedValue!=='0'&&
                     rightRangeTypedValue!=='0'&&
                     priceInfo?.price!=="0"? <>
                    <Flex pt={3} justifyContent="center">
                      <Text fontSize='12px'>{TranslateString(11121,"Current Price:")}{toPrecision(new BigNumber(priceInfo?.price),6)} {tokenB?.symbol} {TranslateString(10044,"per")} {tokenA?.symbol}</Text>
                    </Flex>
                      <Flex>
                         <GridexChart 
                         leftValue={leftRangeTypedValue}
                         rigthValue={rightRangeTypedValue}
                         echartData={chartData}
                         currentPrice={priceInfo?.price}
                         onLeftRangeInput={onLeftRangeInput}
                         onRightRangeInput={onRightRangeInput}
                         />
                          {/* <LiquidityChartRangeInput 
                            leftValue={leftRangeTypedValue}
                            rigthValue={rightRangeTypedValue}
                            onLeftRangeInput={leftEnd}
                            onRightRangeInput={rightEnd}
                            price={new BigNumber(toPrecision(new BigNumber(priceInfo?.price),8)).toNumber() ||0} 
                            chartsData={chartData}/> */}
                      </Flex>
              
                  </>:<Flex flex={1} justifyContent="center" alignItems="center" >
                           {
                              !isValidPairState?TranslateString(11150,"Please reverse tokens sequence"):chartTips()
                           }
                  </Flex>}
            </KeepOutPanel>
        
         
         

            <ExchangeRatePanel state={chartData.length>0 && priceInfo?.price!=="" && priceInfo?.price!=="0"} flexDirection="column">
                
                <Flex style={{height:"115px"}}>
                  <Flex mr={3}><ExchangeRate isHighest={false} chartsData={chartData} values={leftRangeTypedValue} changeValues={leftEnd}/></Flex>
                  <Flex ><ExchangeRate isHighest  chartsData={chartData} values={rightRangeTypedValue} changeValues={rightEnd}/></Flex>
                </Flex>

                <Flex mt={3} >
                  <StyleButton onClick={()=>setStatus(!status)} variant='secondary' style={{width:"100%"}} size="sm" >
                    {TranslateString(11111,"Full Range")}
                  </StyleButton>

                 
                </Flex>

                { status?<EfficiencyWaringPanel onClick={()=>{setStatus(!status)}}>
                           <EfficiencyWaring  sureAllRange={setRangValue}/>
                </EfficiencyWaringPanel>:""
                }
            </ExchangeRatePanel>
        </>
    )

    
    // token数量范围组件
    const rangAmount=()=>(
      <Flex mt={3} flex={1} flexDirection="column">
        <RechargeAmount chartData={chartData} state={state} result={priceInfo?.result} Bprice={priceInfo?.price} />
      </Flex>
    )
    return (
          <BodyWrapper>
            
            <Wrapper id="agg-swap-page">
            <PageHeader title={TranslateString(10028,"Add Liquidity")} />
            <Flex pt={4} flexDirection={isResponse?"column":"row"}>
              <Flex  flex={1} flexDirection="column">
                  {!clearState?<SelectToken chartData={chartData} />:""}
                  {isResponse?<Flex flexDirection="column" mt={3}>{conversionRate()}</Flex>:rangAmount()}
              </Flex> 

              <Flex ml={isResponse?0:4} flex={1} flexDirection="column">
                  {!isResponse?conversionRate():rangAmount()}
                <Flex flex={1}  pt={2} flexDirection="column">
                      {/* <Flex justifyContent='space-between' mt={1}>
                        <Text fontSize="14px">{TranslateString(10007,'Slippage Tolerance')}</Text>
                        <Text fontSize="14px">{userSlippageTolerance / 100}%</Text>
                      </Flex> */}

                      <ButtonTips pircie={priceInfo?.price||"0"} result={priceInfo?.result}/>
                  </Flex>
              </Flex>

            </Flex>
            </Wrapper>

          </BodyWrapper>
        )
}
export default GriDexAddLiquidity




