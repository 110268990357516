import { MenuEntry } from '@pancakeswap-libs/uikit'

// const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) =>

export type ConfigMenuItemsType = MenuEntry & { TranslateString?: any }

const config:(t: any) => ConfigMenuItemsType[]= (t:any) =>[
  {
    label: t(10063,'Home'),
    icon: 'HomeIcon',
    href: 'https://benswap.cash/',
  },
  {
    label: t(10064,'Trade'),
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: t(10010,'Exchange'),
        href: '/swap'
      },
      {
        label: t(10011,'Liquidity'),
        href: '/pool',
        children:[
          '/create',
          '/find',
          '/add',
          '/add/:currencyIdA',
          '/add/:currencyIdA/:currencyIdB',
          '/remove/v1/:address',
          '/remove/:tokens',
          '/remove/:currencyIdA/:currencyIdB'
        ]
      },
      {
        label: t(11037,'Aggregator'),
        href: '/dexaggregator'
      },
      {
        label: t(11140,'Gridex'),
        href: '/GriDexLiquidityList',
        children:['/GriDexAddLiquidity']
      },
      {
        label: t(11141,'Aggregator Pro'),
        href: '/aggregatorpro'
      },
      {
        label: t(13011,'Limit'),
        href: '/limit',
        children:['/limitSwap','/limitOrder']
      },
      {
        label: t(13056,'P2P'),
        href: '/P2P',
        children:["/P2pOrder",'/P2pSwap/:pairAddr/:orderId']
      }
    ]
  },
  {
    label: t(11016,'Yield'),
    icon: 'PoolIcon',
    items: [
      {
        label: t(10046,'Farms'),
        href: 'https://benswap.cash/farms',
      },
      {
        label: t(10047,'Pools'),
        href: 'https://benswap.cash/pools',
      },
    ],
  },
  {
    label: t(11017,'Town'),
    icon: 'TownIcon',
    items: [
      {
        label: t(11018,'Candy House'),
        href: "https://benswap.cash/candyhouse/explorer",
      },
      {
        label: t(11031,'Charities'),
        href: "https://benswap.cash/charities/explorer",
      },
      {
        label: t(10131,'Cemetery'),
        href: "https://benswap.cash/cemetery",
      }
    ],
  },
  {
    label: t(11004,'Ranches'),
    icon: 'CattleIcon2',
    items: [
      {
        label: t(11005,'Create'),
        href: "https://benswap.cash/ranch/create",
      },
      {
        label: t(11006,'Explorer'),
        href: "https://benswap.cash/ranches",
      },
      {
        label: t(11007,'My Ranches'),
        href: "https://benswap.cash/ranch/my",
      },
      {
        label:t(11008,'Favorites'),
        href: "https://benswap.cash/ranch/favorites",
      },
    ],
  },
  {
    label: t(11034,'Games'),
    icon: 'TicketIcon',
    items: [
      {
        label: t(10076,'Lottery'),
        href: 'https://benswap.cash/lottery',
      },
      {
        label: t(11035,'PokéBen'),
        href: "https://benswap.cash/pokeben",
      },
    ],
  },
  {
    label: t(11001,'Tools'),
    icon: 'ToyIcon2',
    items: [
      {
        label: t(11002,'BenLock'),
        href: "https://benswap.cash/benlock",
      },
      {
        label: t(11003,'Token Factory'),
        href: "https://benswap.cash/sep20",
      },
      {
        label:t(11009,'LP Finder'),
        href: "https://benswap.cash/lpfinder",
      },
      {
        label:t(11036,'Airdrop'),
        href: "https://benswap.cash/airdrop",
      },
      {
        label:t(11101,'Bridge'),
        href: "https://benswap.cash/bridge",
      },
      {
        label:t(11164,'NFT Factory'),
        href: "https://benswap.cash/nftfactory",
      },
    ],
  },  
  {
    label: t(10128,'NFT Market'),
    icon: 'NftIcon',
    items: [
      {
        label: t(10126,'Featured'),
        href: 'https://benswap.cash/nft/featured',
      },
      {
        label: t(11006,'Explorer'),
        href: 'https://benswap.cash/nft/collection',
      },
      {
        label: t(10127,'My Auctions'),
        href: 'https://benswap.cash/nft/myorders',
      },
      {
        label: t(11008,'Favorites'),
        href: 'https://benswap.cash/nft/favorites',
      }
    ],
  },
  {
    label: t(11012,'IBOv2'),
    icon: 'IboIcon',
    items: [
      {
        label: t(10049,'Launch'),
        href: "https://benswap.cash/ibov2/create",
      },
      {
        label: t(11006,'Explorer'),
        href: "https://benswap.cash/ibov2/explorer",
      },
      {
        label:t(11032,'Spotlight'),
        href: "https://benswap.cash/ibov2/spotlight",
      },
      {
        label: t(10051,"My Campaigns"),
        href: "https://benswap.cash/ibov2/my",
      },
      {
        label:t(11008,'Favorites'),
        href: "https://benswap.cash/ibov2/favorites",
      },
      {
        label: t(11033,'Market (v1)'),
        href: "https://benswap.cash/ibo/active",
      },
    ],
  },
  {
    label: t(11013,'Governance'),
    icon: 'GovernmentIcon1',
    items: [
      {
        label: t(11100,'Votes'),
        href: 'https://benswap.cash/votes',
      },
      {
        label: t(11014,'Discussions'),
        href: "https://gov.benswap.cash/",
      },
      {
        label: t(11015,'Proposals'),
        href: "https://vote.benswap.cash/",
      },
    ],
  },
  {
    label: t(10054,"Info"),
    icon: "InfoIcon",
    items: [
      {
        label: t(10072,"Analytics (AMM)"),
        href: "https://info.benswap.cash",
      },
      {
        label: t(10073,"Analytics (Gridex)"),
        href: "https://grid.benswap.cash",
      },
      {
        label: t(10055,"ChartPro"),
        href: "https://chartpro.benswap.cash/",
      },
      {
        label: t(10129,"Market Cap"),
        href: "http://marketcap.benswap.cash/",
      },
      {
        label: "Smartscout",
        href: "https://smartscout.cash/",
      },
      {
        label: "vfat.tools",
        href: "https://vfat.tools/smartbch/benswap/",
      },
      {
        label: "DeBank",
        href: "https://debank.com/protocols/sbch_benswap",
      },
      {
        label: t(11000,'API Endpoints'),
        href: "https://docs.benswap.cash/features/api",
      },
    ],
  },
  {
    label: t(10052,"Audits"),
    icon: 'AuditIcon',
    items: [
      {
        label:  t(10053,"CertiK"),
        href: "https://www.certik.org/projects/bentoken-finance",
      },
    ],
  },
  {
    label: t(10071,"Listings"),
    icon: 'ListingIcon',
    items: [
      {
        label: 'CoinMarketCap',
        href: "https://coinmarketcap.com/currencies/green-ben/",
      },
      {
        label: 'CoinGecko',
        href: "https://www.coingecko.com/en/coins/green-ben",
      },
      {
        label: "Defi Llama",
        href: "https://defillama.com/protocol/benswap",
      },
      {
        label: t(11049,"Yahoo! Finance"),
        href: "https://finance.yahoo.com/quote/EBEN-USD",
      },
      {
        label: t(1437,"Crypto.com Price"),
        href: "https://crypto.com/price/green-ben",
      },
      {
        label: t(1438,"BitDegree"),
        href: "https://www.bitdegree.org/cryptocurrency-prices/green-ben-eben-price",
      },
      {
        label: t(1439,"Binance Price"),
        href: "https://www.binance.com/en/price/green-ben",
      },
      {
        label: t(1440,"Coinbase Price"),
        href: "https://www.coinbase.com/price/green-ben",
      },
    ],
  },
  {
    label: t(10056,"Ecosystem"),
    icon: 'BenIcon',
    items: [
      {
        label: t(10057,"Ben Token (BSC)"),
        href: "https://bentoken.finance/",
      },
      {
        label: t(10058,"BenSwap (BSC)"),
        href: 'https://benswap.finance/',
      },
    ],
  },
  {
    label: t(10059,"More"),
    icon: 'MoreIcon',
    items: [
      {
        label: t(10060,"Github"),
        href: "https://github.com/BenTokenFinance",
      },
      {
        label: t(10061,"Docs"),
        href: "https://docs.benswap.cash",
      },
      {
        label: t(10062,"Amber Testnet"),
        href: 'https://amber.benswap.cash/'
      }
    ],
  },
]

export default config
