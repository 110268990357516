import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import {GlobalNotifications} from 'components/GlobalNotifications'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from './AddLiquidity/redirects'
import MigrateV1 from './MigrateV1'
import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange'
import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
// import Farm from './Farm'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'

import Menu from '../components/Menu'
import SmartSwap from './SmartSwap'
import GriDexAddLiquidity from './GriDexAddLiquidity'
import GridexSmartSwap from './GridexSmartSwap'
import GriDexLiquidityList from './GriDexLiquidityList'
import { RedirectToSmartSwap } from './SmartSwap/redirects'
import { RedirectToGriDexSmartSwap } from './GridexSmartSwap/redirects'
import Limit from './Limit'
import LimitOrder from './LimitOrder'
import LimitSwap from './LimitSwap'
import P2p from './P2p'
import P2pSwap from './P2pSwap'
import { RedirectToGriDexAddLiquidity } from './GriDexAddLiquidity/redirects'




const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 16px;
  min-height: calc(100vh - 152px);
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;

  background-image: url('/static/media/bg.bfd323f2.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function App() {
  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])
  // const apiKey = `${process.env.REACT_APP_CROWDIN_APIKEY}`
  // const projectId = parseInt(`${process.env.REACT_APP_CROWDIN_PROJECTID}`)
  // const fileId = 6
  //
  // const credentials: Credentials = {
  //   token: apiKey
  // }
  //
  // const stringTranslationsApi = new StringTranslations(credentials)
  //
  // const getStoredLang = (storedLangCode: string) => {
  //   return allLanguages.filter(language => {
  //     return language.code === storedLangCode
  //   })[0]
  // }

  // useEffect(() => {
  //   const storedLangCode = localStorage.getItem('pancakeSwapLanguage')
  //   if (storedLangCode) {
  //     const storedLang = getStoredLang(storedLangCode)
  //     setSelectedLanguage(storedLang)
  //   } else {
  //     setSelectedLanguage(EN)
  //   }
  // }, [])
  //
  // const fetchTranslationsForSelectedLanguage = async () => {
  //   stringTranslationsApi
  //     .listLanguageTranslations(projectId, selectedLanguage.code, undefined, fileId, 200)
  //     .then(translationApiResponse => {
  //       if (translationApiResponse.data.length < 1) {
  //         setTranslations(['error'])
  //       } else {
  //         setTranslations(translationApiResponse.data)
  //       }
  //     })
  //     .then(() => setTranslatedLanguage(selectedLanguage))
  //     .catch(error => {
  //       setTranslations(['error'])
  //       console.error(error)
  //     })
  // }
  //
  // useEffect(() => {
  //   if (selectedLanguage) {
  //     fetchTranslationsForSelectedLanguage()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedLanguage])

  // const [on, setOn] = useState(false);
  // const {  activate } = useWeb3React();
  // if(!on){
  //   connWallt('injected',activate)
  //   setOn(true)
  // }

  // const { login } = useAuth()
  // useEffect(() => {
  //    login("injected")
  // }, [login])

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <AppWrapper>
         <GlobalNotifications/>
          {/* <LanguageContext.Provider
            value={{ selectedLanguage, setSelectedLanguage, translatedLanguage, setTranslatedLanguage }}
          >
            <TranslationsContext.Provider value={{ translations, setTranslations }}> */}
              <Menu>
                <BodyWrapper>
                  <Popups />
                  <Web3ReactManager>
                    <Switch>

                      <Route exact strict path='/P2pSwap/:pairAddr/:orderId' component={P2pSwap} />
                      <Route exact strict path='/P2p' component={P2p} />
                      
                      <Route exact strict path='/limitSwap' component={LimitSwap} />
                      <Route exact strict path='/limitOrder' component={LimitOrder} />
                      <Route exact strict path='/limit' component={Limit} />
                      <Route exact strict path='/dexaggregator' component={SmartSwap} />
                      <Route exact strict path='/dexaggregator/:outputCurrency' component={RedirectToSmartSwap} />
                      <Route exact strict path='/swap' component={Swap} />
                      <Route exact strict path='/swap/:outputCurrency' component={RedirectToSwap} />
                      <Route exact strict path='/send' component={RedirectPathToSwapOnly} />
                      <Route exact strict path='/find' component={PoolFinder} />
                      <Route exact strict path='/pool' component={Pool} />
                      <Route exact strict path='/create' component={RedirectToAddLiquidity} />
                      <Route exact path='/add' component={AddLiquidity} />
                      <Route exact path='/add/:currencyIdA' component={RedirectOldAddLiquidityPathStructure} />
                      <Route exact path='/add/:currencyIdA/:currencyIdB' component={RedirectDuplicateTokenIds} />
                      <Route exact strict path='/remove/v1/:address' component={RemoveV1Exchange} />
                      <Route exact strict path='/remove/:tokens' component={RedirectOldRemoveLiquidityPathStructure} />
                      <Route exact strict path='/remove/:currencyIdA/:currencyIdB' component={RemoveLiquidity} />
                      <Route exact strict path='/migrate/v1' component={MigrateV1} />
                      <Route exact strict path='/migrate/v1/:address' component={MigrateV1Exchange} />
                      <Route exact strict path='/GriDexLiquidityList' component={GriDexLiquidityList} />
                      <Route exact strict path='/GriDexAddLiquidity' component={GriDexAddLiquidity} />
                      <Route exact strict path='/GriDexAddLiquidity/:inputCurrency' component={RedirectToGriDexAddLiquidity} />
                      <Route exact strict path='/aggregatorpro' component={GridexSmartSwap} />
                      <Route exact strict path='/aggregatorpro/:outputCurrency' component={RedirectToGriDexSmartSwap} />
                      <Route component={RedirectPathToSwapOnly} />
                    </Switch>
                  </Web3ReactManager>
                  <Marginer />
                </BodyWrapper>
              </Menu> 
            {/* </TranslationsContext.Provider>
          </LanguageContext.Provider> */}
        </AppWrapper>
      </HashRouter>
    </Suspense>
  )
}
